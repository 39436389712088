import { ConfigurableVariant, ConfigurableVariants, SelectedOptions } from './cleanConfigurableProduct';

/**
 * Get selected variant
 * @param variants
 * @param selectedOptions
 */
export function getSelectedVariant(
    variants: ConfigurableVariants,
    selectedOptions?: SelectedOptions,
): ConfigurableVariant | undefined {
    if (!variants || !selectedOptions) {
        return undefined;
    }

    return variants.find((variant) => {
        const match = variant.attributes?.every((attr) => {
            if (!attr || !attr.code || !selectedOptions[attr.code]) {
                return false;
            }

            return selectedOptions[attr.code].value === attr.uid;
        });

        if (match) {
            return variant;
        }
    });
}
