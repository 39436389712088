import type {
    ConfigurableVariants,
    SelectedOptions,
} from 'src/components/ConfigurableOptions/utils/cleanConfigurableProduct';

/**
 * Check if an attribute has available products
 * @param variants
 * @param selectedOptions
 * @param attributeCode
 */
export function attributeHasAvailableProducts(
    variants: ConfigurableVariants,
    selectedOptions: SelectedOptions,
    attributeCode: string,
): ConfigurableVariants {
    if (!variants || !selectedOptions || !attributeCode) {
        return;
    }

    const selections = {
        ...selectedOptions,
    };

    delete selections[attributeCode];

    const preSelectedArray = Object.values(selections);

    return variants?.filter((variant) => {
        const hasMatchingAttribute = variant?.attributes?.some((attribute) => {
            if (!attribute.uid) return false;
            return preSelectedArray[0]?.value === attribute.uid;
        });

        return hasMatchingAttribute;
    });
}
