import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { useDeps } from 'src/hooks/useDeps';
import { ProductList } from 'src/components/CategoryListingPage/ProductList';
import { productListItemsFromQuery } from 'src/components/CategoryListingPage/utils/productListItem';
import { categoryProducts_categoryProducts_items } from 'src/queries/__generated__/categoryProducts';
import { ConfigContext } from 'src/components/AppShell/ConfigProvider';
import { SkeletonItemList } from 'src/components/Helpers/SkeletonItemList';

import GetProductForPost from '../../../../../queries/GetProductForPost.graphql';

import classes from './InlineProducts.module.scss';

export type InlineProductsProps = {
    skus: string[];
};

export const InlineProducts = (props: InlineProductsProps) => {
    const { data, loading } = useQuery(GetProductForPost, {
        variables: {
            skus: props?.skus,
        },
        ssr: useDeps().env.SSR_GQL,
        skip: props?.skus?.length === 0, // Don't run the query if we have no skus
    });

    const items = data?.productDetail?.items || [];
    const { product_url_suffix } = useContext(ConfigContext);
    const products = productListItemsFromQuery(items as categoryProducts_categoryProducts_items[], {
        url_suffix: product_url_suffix,
    });

    // no data yet, loading = show skeleton
    if (loading && products.length === 0) {
        return <SkeletonItemList qty={3} />;
    }

    // NOT loading, but products array is empty
    if (!loading && products.length === 0) {
        console.warn('No Products found from provided SKUs.', {
            skusProvided: props?.skus,
        });
        return null;
    }

    if (products.length > 0) {
        return (
            <div className={classes.InlineProductsWrapper}>
                <ProductList products={products} loading={loading} openProductInNewTab={true} />
            </div>
        );
    }

    return null;
};

export const InlineProductsWrapper = (props: InlineProductsProps) => {
    const { ORIGIN_UL_MAGENTO, ORIGIN_UL_MAGENTO_CLIENT } = useDeps().env;
    const isServer = typeof window === 'undefined';
    const InlineMagentoClient = new ApolloClient({
        name: 'InlineMagentoClient',
        link: new HttpLink({
            uri: isServer ? ORIGIN_UL_MAGENTO : ORIGIN_UL_MAGENTO_CLIENT,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
        cache: new InMemoryCache(),
    });

    return (
        <ApolloProvider client={InlineMagentoClient}>
            <InlineProducts skus={props?.skus} />
        </ApolloProvider>
    );
};

export default InlineProductsWrapper;
