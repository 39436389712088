import {
    categoryProducts_categoryProducts_items_ConfigurableProduct,
    categoryProducts_categoryProducts_items_ConfigurableProduct_configurable_options_values_swatch_data_ImageSwatchData,
} from 'src/queries/__generated__/categoryProducts';
import { DeliveryType, getDeliveryType } from 'src/components/ProductDetail/utils/product-detail.types';
import { ProductType } from 'src/types/global-types';
import { imagePath } from 'src/util/formatUrl';

import { ConfigurableOption, ConfigurableVariant } from './cleanConfigurableProduct';

/**
 * Clean configurable options for Product listing page
 * This function is responsible for cleaning the configurable options into a more usable format
 * @param item
 */
export function cleanProductListConfigurableOptions(
    item?: categoryProducts_categoryProducts_items_ConfigurableProduct | null,
): ConfigurableOption[] | undefined {
    return (
        item?.configurable_options?.map((option) => {
            return {
                attribute_code: option?.attribute_code ?? undefined,
                label: option?.label ?? undefined,
                position: option?.position ?? undefined,
                values: option?.values?.map((value) => {
                    return {
                        uid: value?.uid ?? undefined,
                        label: value?.label ?? undefined,
                        swatch_data: {
                            __typename: value?.swatch_data?.__typename ?? 'TextSwatchData',
                            value: value?.swatch_data?.value ?? undefined,
                            thumbnail:
                                (
                                    value?.swatch_data as categoryProducts_categoryProducts_items_ConfigurableProduct_configurable_options_values_swatch_data_ImageSwatchData
                                )?.thumbnail ?? undefined,
                        },
                    };
                }),
            };
        }) ?? undefined
    );
}

/**
 * Clean configurable variants for Product listing page
 * @param item
 */
export function cleanProductListConfigurableVariants(
    item?: categoryProducts_categoryProducts_items_ConfigurableProduct | null,
): ConfigurableVariant[] | undefined {
    return (
        item?.variants?.map((option) => {
            const deliveryType = getDeliveryType(option?.product?.delivery_type);

            const isHomeDelivery = deliveryType === DeliveryType.DELIVERY_HOME;
            const isDeliveryCentre = deliveryType === DeliveryType.DELIVERY_CENTRE;
            const isDropShip = deliveryType === DeliveryType.DELIVERY_DROPSHIP;

            const isDelivery = isHomeDelivery || isDeliveryCentre || isDropShip;

            return {
                product: {
                    type: (option?.product?.__typename as ProductType) ?? undefined,
                    clickCollect: option?.product?.click_and_collected === 1,
                    delivery: isDelivery,
                    smallImage: {
                        label: option?.product?.small_image?.label ?? 'unknown',
                        url: imagePath(option?.product?.small_image?.url),
                    },
                },
            };
        }) ?? undefined
    );
}
