import { ProductPrice, ProductType } from 'src/types/global-types';
import { productPrice } from 'src/components/ProductDetail/utils/product-detail.types';
import { productName } from 'src/util/productName';
import { cleanLabelData, ProductLabelData } from 'src/components/ProductLabels/utils/cleanLabelData';
import {
    CleanConfigurableOptions,
    cleanProductListConfigurableVariants,
} from 'src/components/ConfigurableOptions/utils';
import { cleanProductListConfigurableOptions } from 'src/components/ConfigurableOptions/utils/cleanProductListConfigurableOptions';
import { getProductImage } from 'src/components/CategoryListingPage/utils/getProductImage';
import { getDeliveryMethod } from 'src/components/CategoryListingPage/utils/getDeliveryMethod';

import {
    categoryProducts_categoryProducts_items,
    categoryProducts_categoryProducts_items_ConfigurableProduct,
    categoryProducts_categoryProducts_items_ConfigurableProduct_labels,
} from '../../../queries/__generated__/categoryProducts';

export interface ProductListItem {
    click_collect: boolean;
    configurableOptions?: CleanConfigurableOptions;
    delivery: boolean;
    energy_label_image?: string | null;
    energy_rating?: string | null;
    description?: string;
    id: number | string;
    labels: ProductLabelData[];
    name: string;
    subTitle?: string;
    price: ProductPrice;
    product_fiche_image?: string | null;
    rating_value?: number;
    review_count?: number;
    sku?: string;
    small_image: {
        label: string;
        url: string;
    };
    type: ProductType;
    url: string;
    isComingSoon: boolean;
}

export function productListItemsFromQuery(
    items: (categoryProducts_categoryProducts_items | null)[],
    options: { url_suffix: string },
): ProductListItem[] {
    return items.filter(Boolean).map((item) => {
        if (!item) throw new Error("trying to process a list item that doesn't exist");

        const minPrice = item.price_range.minimum_price?.regular_price?.value;
        const maxPrice = item.price_range.maximum_price?.regular_price?.value;

        const minSalePrice = item.price_range.minimum_price?.final_price?.value;
        const maxSalePrice = item.price_range.maximum_price?.final_price?.value;

        const discountValue = item.price_range.maximum_price?.discount?.amount_off;

        /**
         * Get the delivery method for the product
         * @returns {{delivery: boolean, click_collect: boolean}}
         */
        const { delivery, click_collect } = getDeliveryMethod(item);
        const isHomeDelivery = delivery;
        const isClickAndCollect = click_collect;
        const isComingSoon = item.is_coming_soon === 1;
        let labels = cleanLabelData<categoryProducts_categoryProducts_items_ConfigurableProduct_labels>(item.labels);

        /**
         * We know that the coming soon label UI matches the labels UI
         * Rather than duplicating a component we can just replace the label data with the coming soon label if it exists
         */
        if (isComingSoon) {
            labels = [
                {
                    label_id: 'coming-soon',
                    name: 'Coming soon',
                    customize_css_container_large: 'background-color: #6D7B83;',
                    type: 'text',
                },
            ];
        }

        /**
         * Get the product image for the product
         * @returns {{url: string, label: string}}
         */
        const { url, label } = getProductImage(item);

        return {
            id: item.id!,
            name: productName(item.name || 'unknown'),
            subTitle: item.subtitle ?? undefined,
            url: '/' + item.url_key! + options.url_suffix,
            small_image: {
                label,
                url,
            },
            price: productPrice(minPrice ?? 0, maxPrice ?? 0, minSalePrice, maxSalePrice, item.special_price, {
                price_per: item.woodies?.price_per ?? undefined,
                price_saving: discountValue !== 0 ? discountValue : undefined,
            }),
            click_collect: isComingSoon ? false : isClickAndCollect,
            delivery: isComingSoon ? false : isHomeDelivery,
            labels: labels,
            energy_rating: item.woodies?.energy_rating ?? undefined,
            product_fiche_image: item.woodies?.product_fiche_image ?? undefined,
            configurableOptions:
                {
                    configurable_options: cleanProductListConfigurableOptions(
                        item as categoryProducts_categoryProducts_items_ConfigurableProduct,
                    ),
                    variants: cleanProductListConfigurableVariants(
                        item as categoryProducts_categoryProducts_items_ConfigurableProduct,
                    ),
                } ?? undefined,
            energy_label_image: item.woodies?.energy_label_image ?? undefined,
            type: item.__typename as ProductType,
            isComingSoon: isComingSoon ?? 'Coming soon',
        };
    });
}
