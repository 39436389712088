import {
    categoryProducts_categoryProducts_items,
    categoryProducts_categoryProducts_items_ConfigurableProduct,
} from 'src/queries/__generated__/categoryProducts';
import { imagePath } from 'src/util/formatUrl';

export function getProductImage(item: categoryProducts_categoryProducts_items) {
    if (item.__typename === 'ConfigurableProduct') {
        const firstConfigurableVariant = (item as categoryProducts_categoryProducts_items_ConfigurableProduct)
            .variants?.[0]?.product;

        return {
            label: firstConfigurableVariant?.small_image?.label || 'unknown',
            url: imagePath(firstConfigurableVariant?.small_image?.url),
        };
    } else {
        return {
            label: item?.small_image?.label || 'unknown',
            url: imagePath(item?.small_image?.url),
        };
    }
}
