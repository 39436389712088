import { allowedParams } from 'src/components/ConfigurableOptions/utils/constants';

import type { ConfigurableOptions, SelectedOptions } from './cleanConfigurableProduct';

/**
 * Set configurable url params
 * @param options
 * @param pathname
 * @param search
 * @param selectedOptions
 */
export function setConfigurableUrlParams(
    options: ConfigurableOptions,
    pathname: string,
    search: string,
    selectedOptions?: SelectedOptions,
): string | undefined {
    // If no options are selected, return the current pathname
    // This might not ever happen as we always have a default selected option
    if (!selectedOptions) {
        return pathname;
    }

    // Create a new URLSearchParams object
    const currentParams = new URLSearchParams(search);

    // Create a new blank URLSearchParams object
    const params = new URLSearchParams();

    // Iterate over the allowed parameters and add them to the new URLSearchParams object if they exist
    allowedParams.forEach((param) => {
        const value = currentParams.get(param);
        if (value) {
            params.set(param, value);
        }
    });

    // Iterate over the selected options and add them to the URLSearchParams object
    Object.keys(selectedOptions).forEach((key) => {
        options?.filter((item) => {
            if (item.attribute_code === key && item.label) {
                const value = item.values?.find((value) => value.uid === selectedOptions[key].value);

                if (!value?.label) return;

                params.set(item.label, value.label);
            }
        });
    });

    // If there are no params, return the current pathname else return the pathname with the params
    return !params.toString() ? pathname : `${pathname}?${params.toString()}`;
}
