import React from 'react';
import { AnyEventObject } from 'xstate/lib/types';
import { Text } from '@wearejh/rx-form';

import { ConfigurableOption } from 'src/components/ConfigurableOptions/utils';
import { Select } from 'src/components/Forms/Select';
import { basicPresenceValidation } from 'src/util/forms';

import type { ConfigurableVariants, SelectedOptions } from '../utils/cleanConfigurableProduct';

import classes from './ConfigurableProductOptions.scss';
import { OptionSwatch } from './OptionSwatch';

type ConfigurableProductOptionProps = {
    attributeCode: string;
    field: string;
    initialValue?: string;
    option: ConfigurableOption; // no types exported in this version of xstate
    selectedOptions: SelectedOptions;
    send: any;
    validInStockItems: ConfigurableVariants;
};

type SelectOptions = {
    label: string;
    value: string;
    disabled: boolean;
}[];

/**
 * Configurable Product Options Component
 * This component is responsible for rendering the configurable product option attributes
 * @param props
 * @constructor
 */
export function ConfigurableProductOption(props: ConfigurableProductOptionProps) {
    if (!props.option.values) {
        return null;
    }

    /**
     * Woodies have requirements for swatch values
     * If the option values length is less than 5 then we show the swatches as a swataches colour/text
     * However if the length is greater than 5 we show the swatches as a select field
     * As this is not possible within magento the FE has logic to handle this
     *
     * The renderSwatch function serves two purposes,
     * 1. It returns the nodes to render
     * 2. It returns the options to render in the select field
     * Then we can check if the select field should be shown and use correct values from the function
     * This was done because we need access to all the values to render the swatches
     */
    const showSelectField = props.option.values.length > 5;

    function renderSwatch(): {
        nodes?: JSX.Element[];
        options: SelectOptions;
    } {
        const options: SelectOptions = [];

        const nodes = props.option?.values?.map((value) => {
            const isActive = props.initialValue === value.uid;

            /**
             * Configurable products are not set up in a usual way, where all simple products are assigned to a configurable product in or out of stock
             * Only the in stock products are assigned to the configurable product
             * However all configurable options are still displayed on the page
             * This means that we need to check if the option is available in any of the variants
             */
            const hasAvailableProduct = props.validInStockItems?.some((item) => {
                return item?.attributes?.some((attribute) => attribute.uid === value.uid);
            });

            /**
             * Check if the option is disabled
             * If the option is not available in any of the variants, disable it
             * If the option is not available based on the selected options, disable it
             * This allows the user to see unavailable options on page load and during selections
             */
            const isDisabled =
                (value.uid && props.option.unavailableValues?.includes(value.uid)) ||
                (props.validInStockItems && props.validInStockItems?.length > 0 && !hasAvailableProduct);

            options.push({
                value: value.uid ?? '',
                label: value.label ?? '',
                disabled: Boolean(isDisabled),
            });

            return (
                <OptionSwatch
                    key={value.uid}
                    value={value}
                    attributeCode={props.attributeCode}
                    isDisabled={isDisabled}
                    isActive={isActive}
                    field={props.field}
                    send={props.send}
                />
            );
        });

        return {
            options,
            nodes,
        };
    }

    const { options, nodes } = renderSwatch();

    if (showSelectField) {
        return (
            <Select
                initialValue={props.initialValue}
                onChange={(e) => {
                    const label = props.option?.values?.find((value) => value.uid === e.target.value);

                    props.send({
                        type: 'SELECT_OPTION',
                        data: {
                            attributeCode: props.attributeCode,
                            value: e.target.value ?? undefined,
                            label: label?.label ?? undefined,
                        },
                    } as AnyEventObject);
                }}
                field={props.field}
                id={props.field}
                options={options}
            />
        );
    }

    return (
        <div className={classes.configOptionList}>
            <Text
                field={props.field}
                initialValue={props.initialValue}
                hidden={true}
                validate={basicPresenceValidation()}
            />
            {nodes}
        </div>
    );
}
