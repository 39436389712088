import React from 'react';

import { ConfigurableOptionValue } from 'src/components/ConfigurableOptions/utils';

import classes from './TextSwatch.scss';

type TextSwatchProps = {
    isDisabled?: boolean;
    value: ConfigurableOptionValue;
    variant?: 'pdp' | 'plp';
};

export function TextSwatch(props: TextSwatchProps) {
    return (
        <span
            data-ui-variant={props.variant}
            data-disabled={props.isDisabled}
            className={classes.configOptionSwatchText}
            aria-label={`Select ${props.value.label}`}
        >
            {props.value.swatch_data?.value}
        </span>
    );
}
