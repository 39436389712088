import React, { useCallback, useState } from 'react';
import { AnyEventObject } from 'xstate/lib/types';
import { useFormApi } from '@wearejh/rx-form/lib/hooks/useFormApi';

import { ConfigurableOptionValue } from '../utils';

import classes from './ConfigurableProductOptions.scss';
import { ColourSwatch } from './ColourSwatch';
import { TextSwatch } from './TextSwatch';
import { ImageSwatch } from './ImageSwatch';

type OptionSwatchProps = {
    attributeCode: string;
    field: string;
    isActive: boolean;
    isDisabled?: boolean;
    send: any;
    value: ConfigurableOptionValue;
};

/**
 * Option Swatch Component
 * This component is responsible for rendering the swatch options for a configurable product
 * @param props
 * @constructor
 */
export function OptionSwatch(props: OptionSwatchProps) {
    const formApi = useFormApi();
    const [showTooltip, setShowTooltip] = useState(false);

    const onClick = useCallback(() => {
        if (props.isActive) {
            formApi.setValue(props.field, undefined);

            props.send({
                type: 'SELECT_OPTION',
                data: {
                    attributeCode: props.attributeCode,
                    value: undefined,
                    label: undefined,
                },
            } as AnyEventObject);
        } else {
            formApi.setValue(props.field, props.value.uid);

            props.send({
                type: 'SELECT_OPTION',
                data: {
                    attributeCode: props.attributeCode,
                    value: props.value.uid,
                    label: props.value.label,
                },
            } as AnyEventObject);
        }
    }, [formApi, props]);

    return (
        <button
            key={props.value.uid}
            aria-label={`Select ${props.value.label}`}
            aria-pressed={props.isActive}
            aria-describedby={props.value.uid}
            aria-disabled={props.isDisabled}
            data-ui-state-active={props.isActive}
            disabled={props.isDisabled}
            type="button"
            className={classes.configOption}
            onClick={onClick}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {props.value.swatch_data?.__typename === 'ImageSwatchData' && (
                <ImageSwatch value={props.value} isDisabled={props.isDisabled} variant="pdp" />
            )}

            {props.value.swatch_data?.__typename === 'ColorSwatchData' && (
                <>
                    {showTooltip && (
                        <div className={classes.configOptionTooltip} role="tooltip" id={props.value.uid}>
                            <span
                                className={classes.configOptionTooltipValue}
                                style={{ backgroundColor: `${props.value.swatch_data?.value}` }}
                            />
                            {props.value.label}
                        </div>
                    )}
                    <ColourSwatch value={props.value} isDisabled={props.isDisabled} variant="pdp" />
                </>
            )}

            {props.value.swatch_data?.__typename === 'TextSwatchData' && (
                <TextSwatch value={props.value} isDisabled={props.isDisabled} variant="pdp" />
            )}
        </button>
    );
}
