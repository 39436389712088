import React from 'react';

import { ConfigurableOptionValue } from 'src/components/ConfigurableOptions/utils';

import classes from './ColourSwatch.scss';

type ColourSwatchProps = {
    isDisabled?: boolean;
    value: ConfigurableOptionValue;
    variant?: 'pdp' | 'plp';
};

export function ColourSwatch(props: ColourSwatchProps) {
    return (
        <span
            data-ui-variant={props.variant}
            data-disabled={props.isDisabled}
            className={classes.configOptionSwatchColor}
            style={{ backgroundColor: `${props.value.swatch_data?.value}` }}
            aria-label={`Select ${props.value.label}`}
        ></span>
    );
}
