import qs from 'qs';

import { getSelectedVariant } from 'src/components/ConfigurableOptions/utils/getSelectedVariant';
import { allowedParams } from 'src/components/ConfigurableOptions/utils/constants';

import {
    ConfigurableOptions,
    ConfigurableVariant,
    ConfigurableVariants,
    SelectedOptions,
} from './cleanConfigurableProduct';

/**
 * Set initial selected options
 * @param options
 * @param variants
 * @param search
 */
export function setInitialSelectedOptions(
    options: ConfigurableOptions,
    variants: ConfigurableVariants,
    search,
): {
    initialProduct?: ConfigurableVariant;
    selectedOptions: SelectedOptions;
} {
    const params = search.substr(1);
    const obj = qs.parse(params);
    const initialSelectedOptions: SelectedOptions = {};
    const excludedParams = allowedParams;
    const urlParams = Object.keys(obj).filter((key) => !excludedParams.includes(key));

    const hasValues = Object.values(urlParams).length > 0;
    let getFirstVariant = variants?.find((variant) => variant.product?.stock_status === 'IN_STOCK');

    if (!hasValues) {
        /**
         * If no URL parameters exist, set the initial selected options based on the first variant in stock.
         */
        const firstVariantAttributes = getFirstVariant?.attributes;

        firstVariantAttributes?.forEach((attribute) => {
            if (attribute.code && attribute.uid) {
                initialSelectedOptions[attribute.code] = {
                    value: attribute.uid,
                    label: attribute.label,
                };
            }
        });
    } else {
        /**
         * else set the initial selected options based on the URL.
         */
        Object.keys(obj).forEach((key) => {
            const selectedOption = options?.find((option) => key === option.label);

            if (selectedOption && selectedOption.attribute_code) {
                const selectedOptionValue = selectedOption.values?.find((value) => value.label === obj[key]);

                if (!selectedOptionValue) return;

                initialSelectedOptions[selectedOption.attribute_code] = {
                    value: selectedOptionValue.uid,
                    label: selectedOptionValue.label,
                };
            }
        });

        /**
         * Now we know the initial selected options, we can find the selected variant.
         */
        getFirstVariant = getSelectedVariant(variants, initialSelectedOptions);
    }

    return {
        selectedOptions: initialSelectedOptions,
        initialProduct: getFirstVariant,
    };
}
