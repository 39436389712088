import React from 'react';

import { ConfigurableOptionValue } from 'src/components/ConfigurableOptions/utils';

import classes from './ImageSwatch.scss';

type ImageSwatchProps = {
    isDisabled?: boolean;
    value: ConfigurableOptionValue;
    variant?: 'pdp' | 'plp';
};

export function ImageSwatch(props: ImageSwatchProps) {
    return (
        <span
            data-ui-variant={props.variant}
            data-disabled={props.isDisabled}
            className={classes.configOptionSwatchImage}
            style={{ backgroundImage: `url(${props.value.swatch_data?.thumbnail})` }}
            aria-label={`Select ${props.value.label}`}
        />
    );
}
