import React from 'react';

import { ErrorFor } from 'src/components/Forms/ErrorFor';
import { attributeHasAvailableProducts } from 'src/components/ConfigurableOptions/utils';
import { ConfigurableProductOption } from 'src/components/ConfigurableOptions/components/ConfigurableProductOption';

import type { ConfigurableOptions, ConfigurableVariants, SelectedOptions } from '../utils/cleanConfigurableProduct';

import classes from './ConfigurableProductOptions.scss';

type ConfigurableProductOptionsProps = {
    isProcessing: boolean;
    options: ConfigurableOptions;
    selectedOptions: SelectedOptions;
    send: any; // no types exported in this version of xstate
    variants: ConfigurableVariants;
};

/**
 * Configurable Product Options Component
 * This component is responsible for rendering the configurable product option attributes
 * @param props
 * @constructor
 */
export function ConfigurableProductOptions(props: ConfigurableProductOptionsProps) {
    return (
        <div className={classes.configOptionAttributes}>
            {props.options?.map((option) => {
                const attributeCode = option?.attribute_code;
                const field = `selected_options.${attributeCode}`;

                if (!attributeCode) {
                    return;
                }

                const initialValue = props.selectedOptions?.[attributeCode]?.value;
                const selectedLabel = props.selectedOptions?.[attributeCode]?.label;

                const validInStockItems = attributeHasAvailableProducts(
                    props.variants,
                    props.selectedOptions,
                    attributeCode,
                );

                return (
                    <div key={option.attribute_code} data-isProcessing={props.isProcessing}>
                        <h3 className={classes.configOptionAttributeTitle}>
                            {option.label}: <b>{selectedLabel}</b>
                        </h3>

                        <ConfigurableProductOption
                            option={option}
                            attributeCode={attributeCode}
                            selectedOptions={props.selectedOptions}
                            send={props.send}
                            validInStockItems={validInStockItems}
                            field={field}
                            initialValue={initialValue}
                        />

                        <ErrorFor field={field} />
                    </div>
                );
            })}
        </div>
    );
}
