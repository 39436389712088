import React from 'react';

import { CleanConfigurableOptions } from '../utils';

import { ColourSwatch } from './ColourSwatch';
import { TextSwatch } from './TextSwatch';
import { ImageSwatch } from './ImageSwatch';
import classes from './ProductListConfigurable.scss';

type ProductListConfigurableProps = {
    configurableOptions?: CleanConfigurableOptions;
};

export function ProductListConfigurable(props: ProductListConfigurableProps) {
    if (!props.configurableOptions?.configurable_options) {
        return null;
    }

    /**
     * If there are more than 2 attributes assigned to a product
     * Regardless of how many configurable options are available
     * Always show the generic message
     */
    const showGenericOptionsMessage = props.configurableOptions.configurable_options?.length > 1;

    if (showGenericOptionsMessage) {
        return (
            <div className={classes.root}>
                <small className={classes.message}>More size and colour options available</small>
            </div>
        );
    }

    /**
     * If there is only one attribute assigned to a product
     * Show the options as either text, colour or image swatches
     */
    return (
        <div className={classes.root}>
            {props.configurableOptions.configurable_options?.map((option) => {
                if (!option.values) {
                    return;
                }

                const showPlusCounter = option.values.length > 5;
                const IsText = option.values.every((value) => value.swatch_data?.__typename === 'TextSwatchData');
                const IsColour = option.values.every((value) => value.swatch_data?.__typename === 'ColorSwatchData');
                const IsImage = option.values.every((value) => value.swatch_data?.__typename === 'ImageSwatchData');

                /**
                 * If there are more than 5 options available, and it is a text swatch
                 */
                if (showPlusCounter && IsText) {
                    return <small className={classes.message}>More sizes available</small>;
                }

                return (
                    <ul className={classes.list} data-ui-variant-text={IsText} key={option.attribute_code}>
                        {option.values.map((value, index) => {
                            /**
                             * If the option is a colour or image swatch
                             * If there are more then 5 options, only show the first 4
                             * And show a counter of how many options are available
                             */
                            if (showPlusCounter && (IsColour || IsImage) && index > 3) {
                                return;
                            }

                            return (
                                <li key={value.uid} className={classes.item}>
                                    {IsImage && <ImageSwatch value={value} isDisabled={false} variant="plp" />}

                                    {IsColour && <ColourSwatch value={value} isDisabled={false} variant="plp" />}

                                    {IsText && (
                                        // If there are more then 5 options, only show the first 5
                                        // This code will never be reached because of the condition above
                                        <TextSwatch value={value} isDisabled={false} variant="plp" />
                                    )}
                                </li>
                            );
                        })}

                        {showPlusCounter && (IsColour || IsImage) && (
                            <li className={classes.item}>
                                <span className={classes.plusCounter}>+{option.values.length - 4}</span>
                            </li>
                        )}
                    </ul>
                );
            })}
        </div>
    );
}
