import { categoryProducts_categoryProducts_items } from 'src/queries/__generated__/categoryProducts';
import { DeliveryType, getDeliveryType } from 'src/components/ProductDetail/utils/product-detail.types';

export function getDeliveryMethod(item: categoryProducts_categoryProducts_items): {
    click_collect: boolean;
    delivery: boolean;
} {
    if (!item) {
        return {
            click_collect: false,
            delivery: false,
        };
    }

    let hasClickCollect = false;
    let hasDelivery = false;

    if (hasClickCollect && hasDelivery) {
        return {
            click_collect: hasClickCollect,
            delivery: hasDelivery,
        };
    }

    const checkDeliveryType = (type: number): boolean => {
        const deliveryType = getDeliveryType(type);

        if (!deliveryType) {
            return false;
        }

        return [DeliveryType.DELIVERY_HOME, DeliveryType.DELIVERY_CENTRE, DeliveryType.DELIVERY_DROPSHIP].includes(
            deliveryType,
        );
    };

    if (item.__typename === 'ConfigurableProduct') {
        item.variants?.forEach((variant) => {
            if (variant?.product?.click_and_collected === 1) {
                hasClickCollect = true;
            }
            if (variant?.product?.delivery_type && checkDeliveryType(variant.product.delivery_type)) {
                hasDelivery = true;
            }
        });
    } else {
        if (item.click_and_collected === 1) {
            hasClickCollect = true;
        }
        if (item.delivery_type && checkDeliveryType(item.delivery_type)) {
            hasDelivery = true;
        }
    }

    return {
        click_collect: hasClickCollect,
        delivery: hasDelivery,
    };
}
